import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import Gallery from "@browniebroke/gatsby-image-gallery"
import "@browniebroke/gatsby-image-gallery/dist/style.css"

export const query = graphql`
  query {
    allContentfulGalleryImage {
      edges {
        node {
          image {
            id
            caption: description
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyContentfulFluid_withWebp
            }
            full: fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`

const StyledImages = styled.div`
  .image-container {
    .gatsby-image-wrapper {
      /* border-radius: 1rem; */
      transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
      cursor: pointer;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0);

      &:hover {
        transform: translateY(-1rem);
        box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.4);
      }
    }
  }
`

const ImagesPage = props => {
  const images = props.data.allContentfulGalleryImage.edges.map(
    edge => edge.node.image
  )

  return (
    <Layout>
      <SEO title="Bilder | Spritzgießführerschein.de" />
      <StyledImages className="container">
        <h1 className="text-center mb-5 text-uppercase">
          <strong>Bilder</strong>
        </h1>
        <div className="image-container">
          <Gallery images={images} />

          {/* {props.data.allContentfulGalleryImage.edges.map(edge => (
            <div key={edge.node.image.id} className="image">
              <Img fluid={edge.node.image.fluid} />
              {edge.node.image.description}
            </div>
          ))} */}
        </div>
      </StyledImages>
    </Layout>
  )
}

export default ImagesPage
